import { RiHeartAddFill } from 'react-icons/ri'
import { RiVipCrownFill } from 'react-icons/ri'
import { CgGym } from 'react-icons/cg'

export const plansData = [
  {
    icon: <RiHeartAddFill />,
    name: "BASIC PLAN",
    price: "25",
    features: [
      "2 hours of excercises",
      "Free consultaion to coaches",
      "Access to The Community",
    ],
  },
  {
    icon: <RiVipCrownFill />,
    name: "PREMIUM PLAN",
    price: "30",
    features: [
      "5 hour of excercises",
      "Free consultaion of Coaches",
      "Accessto minibar",
    ],
  },
  {
    icon: <CgGym />,
    name: "PRO PLAN",
    price: "45",
    features: [
      "8 hours of excercises",
      "Consultation of Private Coach",
      "Free Fitness Merchandises",
    ],
  },
];
